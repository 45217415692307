import React, { useContext, useEffect, useRef, useState } from 'react';
import { get_leads, add_tag, add_note, search_lead, search_tags, add_lead, get_lead, get_sellers, delete_lead, get_sources } from '../../../services/api/Leads';
import { Elastic_Search, Checkbox_, Builder_, Dropdown_, Filters_, Input_, Main_, Main_Plain, PageAlwaysVisible, PageFull, PageTitle, Slider_, TD, TH, TR, H2, Table, Textarea_, Notes_, On_Click_Card, Grey_, Focus_, Red_, Confirmation_, AlertContext, Alert_, Grey_Link, Main_Loader, Partial_Loader, Partial_Text_Loader } from 'monica-alexandria'
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import Leads_Add from './partials/Leads_Adds';
import { useSSR, useTranslation } from 'react-i18next';
import Leads_Filters from './partials/Leads_Filters';
import { alertMessage } from '../../../helpers/messagesAlerts';
import { checkPermissions } from '../../../helpers/checkPermissions';
import store from '../../../redux/store';
import { Leads_Edit } from './partials/Leads_Edit';
import Leads_History from './partials/Leads_History';
import { useNavigate, useParams } from 'react-router-dom';
import { handleSelections, handleCloseSlider, handleSave, getLeads, choice_no, choice_yes, getUserInfoOfAssignedSeller, displayOpenTagAlongsideUnregistered, tagsList, getSellers, handleFilters } from './utils';

const LeadPage = styled(PageFull)`
	.SliderFilters{
		.Main{
			opacity: ${p => p.loadingFilters? '0.5' : '1'} !important;
			pointer-events: ${p => p.loadingFilters? 'none' : 'auto'} !important;
			/* pointer-events: none !important;
			opacity: 0.5 !important; */
		
		}
	}
	.Filters{

		.FormGroup{
			width: 100%;
		}
	}

	 .SliderTitle #disabledSave {
		pointer-events: none !important;
		opacity: 0.5 !important;
	}
`

const LeadPageTags = styled.div`
  display: flex;
  gap: .5rem;
  flex-flow: wrap;


`

const LeadPageTag = styled.div`
  padding: var(--smallPads);
  border-radius: .5rem;
  background: ${p => p.type === 'custom' ? 'var(--greyDark)' : 'var(--main)'};
  color: var(--white);
  

  h5{
	white-space: nowrap;
  }
`



const ActionsContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const LeadActions = styled.div`
	display: flex;
	align-items: center;
`
const LeadTD = styled(TD)`
	max-width: 30rem;
	word-wrap: break-word;
`

export default function LEADS() {

	let initialUserRef = useRef(null); 
	const [searchTerm, setSearchTerm] = useState('');
	const [search] = useDebounce(searchTerm, 1000);

	const {t} = useTranslation();

	const [pageNo, setPageNo] = useState(1);
	const [totalLeads, setTotalLeads] = useState(0);
	const [mergeConfirmation, setMergeConfirmation] = useState(false)
	const pageSize = 50;

	const [leads, setLeads] = useState([]);
	const [selectedLead, setSelectedLead] = useState(null);
	const [note, setNote] = useState('');
	const [customTag, setCustomTag] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingFilters, setLoadingFilters] = useState(false);
	
	const [slider, setSlider] = useState('');
	const {alerts,setAlerts} = useContext(AlertContext);

	const isScout =store.getState()?.user?.user?.roles.includes('Scout');

	console.log('isScout', isScout, store.getState()?.user?.user?.roles);
	
    // const [renderedFilterDates, setRenderedFilterDates] = useState('')

	const navigate = useNavigate();
	const {lang} = useParams();

	const [leadToDelete, setLeadToDelete] = useState(false)


	const hasPermission = checkPermissions('Baron')
	
	
	const [filters, setFilters] = useState({
		auto: [],
		custom:[],
		source: [],
		assign: [],
	});

	const [sources, setSources] = useState([]);

	const [user, setUser] = useState('');
	const [sellers, setSellers] = useState([]);
	
	const [actions, setActions] =useState([])
	const [actionsDB, setActionsDB] =useState([])
	const [actionsToDlt, setActionsToDlt] =useState([])

	const [selectionA, setSelectionA] = useState(false);
	const [selectionB, setSelectionB] = useState(false);
	const [confirmation, setConfirmation] = useState(false);

	const [dateFilters, setDateFilters] = useState({startDate:'', endDate:''})
	
	const initialNewLead = {
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		assignee: '',
		customTag: '',
		note:''
	}
	const [newLead, setNewLead] = useState(initialNewLead);

	const handleEditClick = (lead) => {
		setSelectedLead(lead);
		setActionsDB(lead?.actions)
		setSlider('edit')
		setNote('');
		setCustomTag('');
		setActionsToDlt([])
		// setUser(getUserInfoOfAssignedSeller(lead));
	 
		const assignedSeller = getUserInfoOfAssignedSeller(lead);
		setUser(assignedSeller);
	
		// Store the initial user value in the ref
		initialUserRef.current = assignedSeller;
	};

	const handleHistoryClick = (lead) => {
		setSelectedLead(lead);
		setSlider('history')
	};

	const handleAddClick = () => {
		setSlider('add')
		setNote('');
		setCustomTag('');
		setUser('');
		setNewLead(initialNewLead)
	};

	const handleFilterClick = () => {
		setSlider('filters')
	};


	const handleAddNote = async () => {

			if (selectedLead?.note) {
			await add_note(selectedLead)
				.catch(err => {
					console.log(err)
				})

			let data = await get_lead(selectedLead).catch(err => {
				console.log(err)
			})

			setSelectedLead({...selectedLead, note: '', notes: data?.notes || []});
		 }
		getLeads(pageNo-1, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider, search, filters, dateFilters);

	};
	

	const handleAdd = async () => {

		// if (newLead?.email) {
			const cleanedLead = {
				...newLead,
				firstname: newLead.firstname.trim(),
				lastname: newLead.lastname.trim(),
				
			};
			
			await add_lead(cleanedLead, actions)
				.then(res=>{
					if (res.data.code === 'T4000') alertMessage(alerts, setAlerts, (t('Lead with the same email or phone already exists')), 'Error');
					else if (res.data.code === 'T2003'){
						alertMessage(alerts, setAlerts,  (t('Lead added successfully')), 'Success');
						handleCloseSlider(setSelectedLead, setSlider);
					}
					else {
						alertMessage(alerts, setAlerts, (t('There was an error. Please try again')), 'Error');
						handleCloseSlider(setSelectedLead, setSlider);
					}
					
				})
				.catch(err => {
					console.log(err)
				})
				setActions([])
		getLeads(1, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider);
	};

	const getSources = () => {
		get_sources()
		  .then(res=>{
			  setSources(res)
		  })
		  .catch(err => {
			console.log(err)
		})
	}

	const handleApply = async () => {
		setTotalLeads(null)
		search_tags(filters, dateFilters, setLeads,setLoadingFilters)
		.then(res => {
	
			setLoadingFilters(false)
		})
		.catch(err => {
			console.log(err)
		})
		

		handleCloseSlider(setSelectedLead, setSlider);
	};

	const handleClear = async () => {
		getLeads(1, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider);

		setFilters({
			auto: [],
			custom:[],
			source: [],
			assign: [],
		})

		setDateFilters({startDate:'', endDate:''})

		handleCloseSlider(setSelectedLead, setSlider);
	};



	const canMergeLeads = (selectedLead) => {
		if (!selectedLead?.mergeLead || !selectedLead?.mergeLeadEmails) {
			console.log("No mergeLead or mergeLeadEmails provided");
			return false;
		}
	
		const { mergeLead, mergeLeadEmails, email, emails, emailList } = selectedLead;
	
		// Combine all email lists for comparison
		const allEmails = new Set([
			email,                       // Add the primary email
			...emails || [],    
			...emailList ||[],             // Add emails array
			// ...selectedLeadEmailList     // Add external email list
		]);
	
		// Check if mergeLead exists in the combined email list
		if (allEmails.has(mergeLead)) {
			console.log("mergeLead exists in one of the email lists");
			return false; // Cannot merge
		}
	
		// Check if any mergeLeadEmails item exists in the combined email list
		const hasConflictingEmails = mergeLeadEmails.some((mergeEmail) => allEmails.has(mergeEmail));
		if (hasConflictingEmails) {
			console.log("One of mergeLeadEmails exists in the email lists");
			return false; // Cannot merge
		}
	
		return true; // Safe to merge
	};
	
	

	const handleDeleteLead = (lead) =>{

		delete_lead(lead?._id)
		.then(res => { 
			alertMessage(alerts, setAlerts, (t('Lead deleted successfully')), 'Success');
			setLeadToDelete(false)
			getLeads(pageNo-1, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider);
		})
		.catch(err => console.log(err))

		handleCloseSlider(setSelectedLead, setSlider);
	}
	
	useEffect(() => {
		if (search) {

			// setLoading(true)
		
			setTotalLeads(null)

			search_lead(search)
				.then(data => {
					setLeads(data)
					// setLoading(false)

				})
				.catch(err => {
					console.log(err)
				})
		}
		else {
			getLeads(1, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider, setActions);
		}

	}, [search]);
	

	useEffect(() => {
		getSellers(setSellers);
		getSources();
	}, []);

	const userChanged = initialUserRef?.current?.id !== user?.id ;

	console.log('LEADS', leads);
	console.log('newLead', newLead);
	console.log('SOURCES', sources);
	console.log('loadingFILTERS', loadingFilters);
	console.log('actionsToDlt', actionsToDlt);
	console.log('filters', filters);

	return (
		<LeadPage loadingFilters={loadingFilters}>
 			
			<Alert_ duration={6000}/>
		  {mergeConfirmation ?
         <Confirmation_
                message={`\nYou are about to merge 2 leads.\n\nContinue?`}
                no={t("No")}
                onNo={() => setMergeConfirmation(false)}
                onYes={() => {
					setMergeConfirmation(false);

					handleSave(selectedLead, setSelectedLead, alerts, setAlerts, setSlider, user, pageNo, t, slider, pageSize, setPageNo, leads, setLeads, setTotalLeads, setSources, setLoading,'', search, filters, dateFilters,actions, setActions, actionsToDlt)
				}}
                yes={t("Yes")}
            /> : <></>}
			{leadToDelete !== false ? 
			 	<Confirmation_
					message={t("Are you sure you want to delete this lead?")}
					no={t("No")}
					onNo={() => setLeadToDelete(false)}
					onYes={() => handleDeleteLead()}
					yes={t("Yes")}
			/> : null }
			{selectedLead && slider == 'history' ? (
				<Slider_ title={`History for: ${selectedLead.lastname} ${selectedLead.firstname}`} onClose={() => handleCloseSlider(setSelectedLead, setSlider)}>
					<Leads_History selectedLead={selectedLead} history={tagsList(selectedLead?.tags)} />
				</Slider_>
			)
			: null}
			{slider == 'filters' ? (
				<Slider_ 
					onClick={handleApply} 
					title={t("Filters")} 
					iconGreyLeft="Delete" 
					onGrey={handleClear} 
					grey={t("Clear all")} 
					text="Apply" 
					className="SliderFilters"
					onClose={() => handleCloseSlider(setSelectedLead, setSlider)}
				>
					{/* TODO: Delete after creating the <Dates_ /> component */}
					{/* <DateFormat>
							<h5>From:</h5>
							<input
								type="date"
								value={startDate}
								onChange={e => setStartDate(e.target.value)}
							/>
							<h5>To:</h5>
							<input
								type="date"
								value={endDate}
								onChange={e => setEndDate(e.target.value)}
							/>
						</DateFormat> */}

					<Leads_Filters 
						onSource={(value) => handleFilters('source', value, setFilters)}
						checkedQuestad={filters?.source?.includes("Questad")}
						checkedQuestadForm={filters?.source?.includes("Questad form")}
						checkedAdSnapForm={filters?.source?.includes("AdSnap form")}
						checkedStatic={filters?.source?.includes("Static")}
						checkedSources={(source)=>filters?.source?.includes(source)}
						valueDates={dateFilters?.renderedDates}
						onDates={(selections) => setDateFilters({...dateFilters, startDate: selections[0],endDate: selections[1], renderedDates: selections})}
						onSellers={(value)=>handleFilters('assign', value, setFilters)}
						sellers={sellers}
						sources={sources}
						//checkedSellers={(seller)=>filters?.assign?.includes(seller)}
						filters={filters}
						onAutomated={(value) => handleFilters('auto', value, setFilters)}
						checkedUnregistered={filters?.auto?.includes("Unregistered")}
						checkedRegistered={filters?.auto?.includes("Registered")}
						checkedPaidQuestad={filters?.auto?.includes("Paid Questad")}
						checkedLostQuestad={filters?.auto?.includes("Lost Questad")}
						onStatus={(value) => handleFilters('custom', value, setFilters)}
						onRecent={(value) => setFilters({...filters, recent: value})}
						checkedRecent={filters?.recent}
						checkedOpen={filters?.custom?.includes("Open")}
						checkedUpsell={filters?.custom?.includes("Upsell")}
						checkedNewCall={filters?.custom?.includes("New Call")}
						checkedAnswered={filters?.custom?.includes("Answered")}
						checkedNotAnswered={filters?.custom?.includes("Not Answered")}
						checkedMeetingSet={filters?.custom?.includes("Meeting Set")}
						checkedCustomerWon={filters?.custom?.includes("Customer Won")}
						checkedCustomerLost={filters?.custom?.includes("Customer Lost") ? true : false}
					/>
				</Slider_>)
			:
				null	
			}
			{selectedLead && slider == 'edit' ? (
				<Slider_ 
					onClick={selectedLead?.mergeLead && selectedLead?.mergeLead !== '' ?
							// () => handleMergeConfirmation()
							() => {
								if (canMergeLeads(selectedLead)) {
									setMergeConfirmation(true);
								} else {
									alertMessage(alerts, setAlerts, t('Merge failed: Cannot merge leads with duplicate emails.'), 'Error');
								}
							}
							:
							 () => handleSave(selectedLead, setSelectedLead, alerts, setAlerts, setSlider, user, pageNo, t, slider, pageSize, setPageNo, leads, setLeads, setTotalLeads, setSources, setLoading, userChanged, search, filters, dateFilters, actions, setActions, actionsToDlt)
							} 
					title="Edit prospect lead" 
					text="Save"
					id={loading ? '' : ''}
					onClose={() => handleCloseSlider(setSelectedLead, setSlider)}
				>
					<Leads_Edit 
						selectedLead={selectedLead}
						setSelectedLead={setSelectedLead}
						sellers={sellers}
						hasPermission={hasPermission}
						handleAddNote={handleAddNote}
						user={user}
						setUser={setUser}
						setCustomTag={setCustomTag}
						note={note}
						setNote={setNote}
						handleDeleteLead={handleDeleteLead}
						initialUserRef={initialUserRef}
						leads={leads}
						actions={actions}
						actionsDB={actionsDB}
						setActions={setActions}
						setActionsDB={setActionsDB}
						setActionsToDlt={setActionsToDlt}
					/>
				</Slider_>
			)
			:
				null	
			}
			{slider == 'add' ? (
				<Slider_ 
					onClick={handleAdd} 
					icon="Add" 
					title={("Add prospect lead")} 
					text={t("Add")} 
					iconGreyLeft="Xicon" 
					grey={t("Cancel")} 
					onGrey={() => handleCloseSlider(setSelectedLead, setSlider)}
					onClose={() => handleCloseSlider(setSelectedLead, setSlider)}
				>
					<Leads_Add 
						newLead ={newLead}
						setNewLead={setNewLead}
						fileAssignee={sellers}
						actions={actions}
						setActions={setActions}
						valueEmail={newLead.email}
						onEmail={(e) => setNewLead({ ...newLead, email: e.target.value })}
					/>
				</Slider_>
			)
			:
				null
			}
			<PageAlwaysVisible>
				<PageTitle>
					<Grey_Link iconLeft='Back' onClick={()=> navigate(`/${lang}/`)}/> 
					<h1>{t("Prospect leads")}</h1>
				</PageTitle>

				<Filters_>
					<Input_
						type="text"
						placeholder="Search lead"
						// de={searchTerm}
						onChange={(e) => {
							setSearchTerm(e.target.value)
						}}
					/>
					{isScout ? null : <Main_ iconLeft="Add" text={t("Lead")} onClick={handleAddClick} /> }
					<Main_Plain iconLeft="Filter" text={t("Filters")} onClick={handleFilterClick} />
				</Filters_>
				{loadingFilters? <Partial_Loader text={'Loadinf leads'} /> : null}

				<Table>
					<TR>
						<TH>{t("A/A")}</TH>
						<TH>{t("Date")}</TH>
						<TH>{("Firstname")}</TH>
						<TH>{("Lastname")}</TH>
						<TH>{("Email")}</TH>
						<TH>{("Phone")}</TH>
						<TH>{("Project codes")}</TH>
						<TH>{("Brand")}</TH>
						<TH>{("Source")}</TH>
						<TH>{("Seller")}</TH>
						<TH>{("Tags")}</TH>
						<TH>{t("Actions")}</TH>
					</TR>
					{leads?.length > 0 && leads?.map((lead, i) => {
						const lastAction = lead?.actions?.filter(action => action?.status === true).at(-1);

					return(
						<TR key={i} onClick={() => handleSelections(lead, selectionA, selectionB, setSelectionA, setSelectionB)}>
							<TD>{i+1}</TD>
							<TD>{new Date(lead?.createdAt).toLocaleDateString('el-GR')}</TD>
							<TD>{lead?.firstname}</TD>
							<TD>{lead?.lastname}</TD>
							<LeadTD>
								{[
									lead?.email || null, 
									...(lead?.emailList || []),
									...(lead?.emails || [])
									
									]
									.filter(email => email)
									.filter((email, index, self) => self.indexOf(email) === index) 
									.join(', ') 
								}
								</LeadTD>
							<LeadTD>
								{[
									lead?.phone || null, 
									...(lead?.phoneList || []),
									...(lead?.phones || [])
									
									]
									.filter(phone => phone)
									.filter((phone, index, self) => self.indexOf(phone) === index) 
									.join(', ') 
								}
								
							</LeadTD>
							<LeadTD>{lead?.projectCodes?.length ? lead.projectCodes?.join(', ') : null}</LeadTD>
							<TD>{lead?.brand || null}</TD>
							<TD>
								<LeadPageTags>
									{lead?.tags?.filter(tag => tag.type === 'source').map((tag, index) => (
										<LeadPageTag key={index} type={tag.type}>
											<h5>{tag.name}</h5>
										</LeadPageTag>
									))}
								</LeadPageTags>
							</TD>
							<TD>
								<LeadPageTags>
									{lead?.histories?.filter(seller => seller.activeSeller === true).map((seller, index) => (
										<LeadPageTag key={index}>
											<h5>{seller?.seller?.username}</h5>
										</LeadPageTag>
									))}
								</LeadPageTags>
							</TD>
							<TD>
								<LeadPageTags>
									{displayOpenTagAlongsideUnregistered(lead)?.map((tag, index) => (
										<LeadPageTag key={index} type={tag?.type}>
											<h5>{tag?.name}</h5>
										</LeadPageTag>
									))}
									{lastAction ? (
										<LeadPageTag type="custom">
											<h5>{lastAction?.action}</h5>
										</LeadPageTag>
									) : null}
								</LeadPageTags>
							</TD>
							<TD>
								<LeadActions>
									{isScout ? null 
									: <Main_Plain iconLeft="Edit" onClick={() => handleEditClick(lead)} />}
									{/* {selectionA && selectionB && lead._id == selectionB?._id ?
										<Main_Plain text={t('Merge!')} onClick={() => handleEditClick(lead)} ></Main_Plain>
										:
										<></>
									} */}
									<Main_Plain iconLeft="File" onClick={() => handleHistoryClick(lead)} />
									{/* <Red_ iconLeft="Delete" onClick={() => setLeadToDelete(lead)} /> */}
								</LeadActions>
								
							</TD>
						</TR>
					)})}
					
				</Table>
				{totalLeads && leads?.length < totalLeads ? (<Main_Plain text="Show more" iconLeft='Loading' onClick={() => {
					getLeads(pageNo, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider);
				}} />) : null}
			</PageAlwaysVisible>
		</LeadPage>
	);
}

